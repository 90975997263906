#footer-container{
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
    margin: 0;
}

.footer-social-button{
    padding: 1rem;
}

.footer-text{
    margin-top: 0;
}

#footer-socials {
    list-style-type: none;
    font-size: 30px;
    display: flex;
    justify-content: center;
}