.post{
    width: 80%;
    margin: auto;
    padding: 5%;
}

.actual-post-title{
    text-decoration: none;
}

.back-button{
    text-decoration: none;
    color: var(--text-color);
}

.post-img{
    display: block;
    max-width: 100%;
    height:auto;
    margin-left: auto;
    margin-right: auto;
}

@media screen and (max-width: 830px){
    .post{
        width: auto;
    }
}