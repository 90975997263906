@font-face {
  font-family: "Regular";
  src: local("SpaceMono-Regular"),
    url("./fonts/SpaceMono/spacemono-regular-webfont.woff2") format("woff2"),
    url("./fonts/SpaceMono/spacemono-regular-webfont.woff") format("woff");
  font-display: auto;
}

@font-face {
  font-family: "Bold";
  src: local("SpaceMono-Bold"),
    url("./fonts/SpaceMono/spacemono-bold-webfont.woff2") format("woff2"),
    url("./fonts/SpaceMono/spacemono-bold-webfont.woff") format("woff");
  font-display: auto;
}

@font-face {
  font-family: "Italic";
  src: local("SpaceMono-Italic"),
    url("./fonts/SpaceMono/spacemono-italic-webfont.woff2") format("woff2"),
    url("./fonts/SpaceMono/spacemono-italic-webfont.woff") format("woff");
  font-display: auto;
}

@font-face {
  font-family: "BoldItalic";
  src: local("SpaceMono-BoldItalic"),
    url("./fonts/SpaceMono/spacemono-bolditalic-webfont.woff2") format("woff2"),
    url("./fonts/SpaceMono/spacemono-bolditalic-webfont.woff") format("woff");
    font-display: auto;
}

:root {
  background-color: #2f3640;
  color: var(--text-color);
  
  
  --background-color: #2f3640;
  --text-color: #f5f6fa;
  --text-secondary-color: #3f3d56;
  --text-hover-color: #95a5a6;

  --background-color-hover: #C6BCBC;
  --alt-background: #006600;
  --link-color: #37fafa;
}

figcaption{
  text-align: center;
}

iframe{
  border: 0;
}

a{
  color: var(--link-color);
}

.extlink{ /* External site links */
  color: var(--link-color);
}

.yt-lite {
  background-color: #000;
  position: relative;
  display: block;
  contain: content;
  background-position: center center;
  background-size: cover;
  cursor: pointer;
}

.yt-lite::after {
  content: "";
  display: block;
  padding-bottom: calc(100% / (16 / 9));
}

.yt-lite > iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.yt-lite > .lty-playbtn:before {
  content: '';
  border-style: solid;
  border-width: 11px 0 11px 19px;
  border-color: transparent transparent transparent #fff;
}

.yt-lite > .lty-playbtn,
.yt-lite > .lty-playbtn:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

body {
    padding-left: 10%;
    padding-right: 10%;
    font-family: 'Regular';
}

@media screen and (max-width: 600px){
  body{
    padding-left: 0;
    padding-right: 0;
  }
}

li a {
  text-decoration: none;
  color: var(--text-color);
}

/* *, *::before, *::after{
  box-sizing: border-box;
} */