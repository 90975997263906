#contentParent{
    color: var(--text-color);
    padding: 5%;
    width: 80%;
    margin: auto;
}

.sectionContent{
    margin-bottom: 8rem !important
}

.certImg{
    margin-right: 2rem;
    margin-bottom: 2rem;
    max-width: 100%;
    height: auto;
}

@media screen and (max-width: 830px){
    #topLineContent{
        width: auto;
    }
}

