.Title { /* Main heading text */
  font-size: 30px;
  font-weight: bold;
  text-align: left;
  display: block;
  padding-left: 20px;
}

.titleNav{
  padding: 0;
}

.navBar { /* Default nav styles */
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 20px;
}

.sideNavbar {
  height: 100%;
  width: 250px;
  position: fixed;
  z-index: 1;
  top: -2%;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  margin: auto;
}

.sideNavbar a{
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: .3s;
}

.sideNavbar li{
  display: block;
}

.mobileButton{
  height: 50px;
  z-index: 2;
  display: none;
}

.navLinks {
  margin-left: auto;
}

.navLinks li{
  display: inline;
  cursor: pointer;
  margin-right: 1.5em;
}

.active{
  border-left: 2.5px solid var(--text-color);
  padding-left: 1em;
  padding-bottom: .1em;
}

.inactive{
  margin: 1em;
}

@media screen and (max-width: 830px){
  .navLinks{
      display: none;
  }

  .mobileButton{
    padding: 0;
    margin: 0;
    margin-left: auto;
    display: block;
  }

  .mobileSocials{
    display: flex;
    position: absolute;
    bottom: 10%;
  }
}
