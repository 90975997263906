/* Colours defined in App.css  */

.post-list{
    padding: 4%;
    width: 55%;
    margin: auto;
}

.post-card{
    text-align: center;
    margin-bottom: 3rem;
    line-height: 1.3;
    padding: 10px;
    margin-bottom: 1rem;
    border-radius: 10px;
    box-shadow: 0 10px 10px hsla(0,0%,0%,0.05);
    transition: 0.25s ease;
}

.post-title{
    text-decoration: none;
    color: var(--text-color);
}

.post-title:hover{
    color: var(--text-hover-color);
}

.post-card:hover{
    transform: translateY(-2px);
    box-shadow: 0 10px 10px hsla(0,0%,0%,0.1);
}

.author{
    display: block;
}

.img-wrapper{
    position: relative;
    object-fit: contain;
    position: relative;
    overflow-y: hidden;
}

.postImg{   
    width: auto;
    height: 200px;
    
}

.post-details-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-seperator{
    margin: 5px; /* Time icon to text spacing */
}

@media screen and (max-width: 830px){
    .postImg{
        display: none;
    }
    .post-list{
        width: auto;
    }
}